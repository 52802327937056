<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item label="所属班级" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-select style='width:100%' placeholder="请选择" v-decorator="rules.classId" showSearch :filterOption="filterOption" mode="multiple" @search="handleSearch">
          <a-select-option v-for="(item, index) of category" :key="index" :value="item.class_id">{{ item.filter_name }}</a-select-option>
        </a-select> 
      </a-form-item>
      <a-form-item label="作业标题" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-input v-decorator="rules.homeworkTitle" :rows="4"/>
      </a-form-item>
      <a-form-item label="作业内容" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-textarea v-decorator="rules.homeworkDesc" :rows="4"/>
      </a-form-item>
      <!-- <a-form-item label="作业打卡次数" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-input v-decorator="rules.homeworkRepeat" :rows="4"/>
      </a-form-item> -->
      <a-form-item label="图片内容" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.homeworkImages" name="file" 
          multiple
          accept='.png,.jpeg,.jpg'
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeImageUpload"
          listType="picture"
          :file-list="fileList"
          @change="changeFile">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="音频内容" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.homeworkAudios" name="file"
          multiple
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeUpload"
          :file-list="fileList2"
          @change="changeFile2">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="视频内容" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.homeworkVideos" name="file"
          multiple
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeUpload"
          :file-list="fileList3"
          @change="changeFile3">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>

      <a-form-item label="资料库文件" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-select allowClear placeholder="请选择" v-decorator="rules.homeworkKnowledge">
          <a-select-option v-for="(item, index) of zlList" :key="index" :value="item.id">{{ item.file_name }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      confirmLoading: false,
      visible: false,
      loading: false,
      ModalTitle: '新增作业',

      form: this.$form.createForm(this),
      rules: {
        classId: [ 'class_id',
          {rules: []}
        ],
        homeworkTitle: [ 'homework_title',
          {rules: [{ required: true, message: '请输入作业标题!' }]}
        ],
        homeworkDesc: [ 'homework_desc',
          {rules: [{ required: true, message: '请输入作业内容!' }]}
        ],
        homeworkRepeat: [ 'homework_repeat', {rules: []} ],
        homeworkImages: [ 'homework_images', {rules: []} ],
        homeworkAudios: [ 'homework_audios', {rules: []} ],
        homeworkVideos: [ 'homework_videos', {rules: []} ],
        homeworkKnowledge: [ 'homework_knowledge', {rules: []} ],
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: [],
      fileList2: [],
      fileList3: [],

      students: [],
      category: [],
      zlList: [],
    }
  },

  async created() {
    this.visible = true
    if(this.item){
      this.loading = true
    }
    await this.getCate()
    await this.getZlList()
    await this.$nextTick()
    if(this.item){
      this.fileList = this.item.homework_images
      this.fileList2 = this.item.homework_audios
      this.fileList3 = this.item.homework_videos
      let obj = {
        class_id:this.item.classes_id.toString(),
        homework_title:this.item.homework_title,
        homework_desc:this.item.homework.homework_desc
      }
      await this.form.setFieldsValue(obj)
      this.loading = false
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getCate(val) {
      let res = await this.$store.dispatch('searchClassesAction',{params:{q:val}})
      this.category = res.data
    },
    async getZlList() {
      let res = await this.$store.dispatch('zlShareListAction', { })
      this.zlList = res.items
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (this.fileList.length) {
            let arr = []
            for (let file of this.fileList) {
              if (file.response.key) {
                arr.push(file.response.key)
              }
            }
            params.homework_images = arr
          }
          if (this.fileList.length) {
            let arr = []
            for (let file of this.fileList2) {
              if (file.response.key) {
                arr.push(file.response.key)
              }
            }
            params.homework_audios = arr
          }
          if (this.fileList.length) {
            let arr = []
            for (let file of this.fileList3) {
              if (file.response.key) {
                arr.push(file.response.key)
              }
            }
            params.homework_videos = arr
          }
          if(this.item){
            params.assign_id = this.item.homework_id
          }
          this.confirmLoading=true
          await this.$store.dispatch('homeworkPublishAction', { data: params })
          .then(res=>{
            this.$message.success("操作成功~")
            this.parent.hideModal(1)
          })
          .catch(err=>{
            this.confirmLoading=false
            this.$message.error("操作失败~")
          })
        }
      } catch {
  
      }
    },
    handleSearch(val){
      this.getCate(val)
    },
    handleCancel() {
      this.parent.hideModal()
    },
    async beforeImageUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('imageTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    changeFile({ file, fileList }) {
      this.fileList = fileList
      console.log(fileList)
    },
    changeFile2({ file, fileList }) {
      this.fileList2 = fileList
      console.log(fileList)
    },
    changeFile3({ file, fileList }) {
      this.fileList3 = fileList
      console.log(fileList)
    },
  }
}
</script>
