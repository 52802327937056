<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <div class="homework-detail" v-if="homework.homework">
      <div class="title">
        <h3>{{homework.homework.homework_title  }}</h3>
        <div class="des">
          <span>{{homework.homework.teacher_name  }}</span>
          <span>{{homework.homework.created_at  }}</span>
        </div>
      </div>
      <div class="cont">
        <div class="desc">{{ homework.homework.homework_desc }}</div>
        <div class="photos">
          <LImg v-for="(item, index) of homework.homework.homework_images" :key="index" :src="item" />
        </div>
      </div>
      <div class="other">
        <div class="tit">未完成学员</div>
        <div class="con">
          <div class="stu" v-for="(item, index) of homework.not_finish" :key="index">
            <div class="photo"><LImg :src="item.student_avatar" /></div>
            <div class="name">{{ item.student_name }}</div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<style lang="scss" scoped>
.homework-detail {
  .title {
    text-align: center;
    h3 {
      font-size: 24px;
      margin: 0;
      font-weight: normal;
    }
    .des {
      font-size: 12px;
      span {
        margin: 0 5px;
      }
    }
  }
  .cont {
    padding-bottom: 30px;
    .desc {
      font-size: 16px;
      line-height: 23px;
      padding-bottom: 30px;
    }
    .photos {
      margin: -5px;
      img {
        width: 105px;
        height: 105px;
        border-radius: 5px;
        margin: 5px;
      }
    }
  }
  .other {
    .tit {
      background: #f1f1f1;
      padding: 5px 10px;
      font-size: 14px;
    }
    .con {
      display: flex;
      flex-wrap: wrap;
      padding-top: 15px;
      .stu {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
        .photo {
          img {
            width: 44px;
            height: 44px;
            border-radius: 50%;
          }
        }
        .name {
          font-size: 14px;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'DetailModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      ModalTitle: '作业详情',
      visible: false,
      confirmLoading: false,
      homework: {}
    }
  },

  created() {
    this.visible = true
    this.getDetail()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail() { 
      let res = await this.$store.dispatch('homeworkDetailAction', { data: { assign_id: this.item.homework_id } })
      console.log(res)
      this.homework = res.data
    },
    handleOk() {
      this.parent.hideDetailModal(1)
    },
    handleCancel() {
      this.parent.hideDetailModal(0)
    }
  }
}
</script>