<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <div class="zl-list">
      <div class="archive-list" style="padding-top: 0;">
        <div class="item" v-for="(item, index) of homework.assign_student" :key="index">
          <a-rate style="position: absolute; top: -5px; right: 0;" v-model="item.homework_comment_rate" @change="changeRate(item)" />
          <div class="user"><LImg :src="item.student_avatar"/></div>
          <div class="text">
            <div class="text1">
              <div class="username">{{ item.student_name }}</div>
              <div class="time">{{ item.homework_finish_at }}</div>
            </div>
            <div class="text2" v-if="!record[item.homework_record_id]"><a style="font-size: 12px;" @click="getRecord(item.homework_record_id)">查看作业</a></div>
            <div v-else>
              <div v-if="record[item.homework_record_id].homework_finish">
                <div class="text3">{{ record[item.homework_record_id].homework_finish.finish_content }}</div>
                <div class="photos">
                  <LImg preview="0" v-for="(img, idx) of record[item.homework_record_id].homework_finish.comment_image" :key="idx" :src="img"/>
                </div>
                <div class="photos">
                  <!-- <av-bars v-for="(img, idx) of record[item.homework_record_id].homework_finish.finish_audios" :key="idx" :audio-src="img.audio_path" /> -->
                  <audio v-for="(img, idx) of record[item.homework_record_id].homework_finish.finish_audios" :key="idx" :src="img.audio_path" controls/>
                </div>
                <div class="photos">
                  <d-player v-for="(img, idx) of record[item.homework_record_id].homework_finish.finish_videos" :key="idx" 
                    :options="{
                      video: {
                        url: img
                      }
                    }"/>
                  <!-- <video v-for="(img, idx) of record[item.homework_record_id].homework_finish.finish_videos" :key="idx" :src="img" controls/> -->
                </div>
              </div>
              <!-- <div class="text4">
                <a class="num">点赞(1)</a>
                <a class="num">评论(2)</a>
                <a class="num">回复</a>
              </div> -->
              <div class="comment-title">
                <div>评论：</div>
                <div class="links">
                  <a @click="showReplyModal(item.homework_record_id)">评论回复</a>
                </div>
              </div>
              <div class="comments">
                <div class="comment" v-for="(comment, idx) of record[item.homework_record_id].homework_comment" :key="idx">
                  <div class="item">
                    <!-- <div class="user"><LImg :src="comment.commnet_avatar"/></div> -->
                    <div class="text">
                      <div class="text1">
                        <div class="username">{{ comment.commnet_name }}</div>
                        <div class="time">{{ comment.comment_at }}</div>
                      </div>
                      <div class="text3">{{ comment.commnet_content }}</div>
                      <div class="photos" v-if="comment.comment_image">
                        <LImg :src="comment.comment_image"/>
                      </div>
                      <div class="photos" v-if="comment.commnet_audio.length">
                        <audio v-for="(img, i) of comment.commnet_audio" :key="i" :src="img.audio_path" controls/>
                      </div>
                      <div class="photos" v-if="comment.comment_video">
                        <video :src="comment.comment_video" controls/>
                      </div>
                    </div>
                    <!-- <a class="delete" @click="deleteComment(item.homework_record_id, comment.commnet_id)">删除</a> -->
                  </div>
                </div>
              </div>
              <div class="comment-title">
                <div>老师点评：</div>
                <div class="links">
                  <a @click="showCommentModal(item.homework_record_id)">添加点评</a>
                </div>
              </div>
              <div class="comments" v-if="record[item.homework_record_id].teacher_comment">
                <div class="comment">
                  <div class="item">
                    <div class="user"><LImg :src="record[item.homework_record_id].teacher_comment.comment_by_avatar"/></div>
                    <div class="text">
                      <div class="text1">
                        <div class="username">{{ record[item.homework_record_id].teacher_comment.comment_by }}</div>
                        <div class="time">{{ record[item.homework_record_id].teacher_comment.comment_at }}</div>
                      </div>
                      <div class="text2">
                        {{ record[item.homework_record_id].teacher_comment.comment_content }}
                      </div>
                      <div class="photos" v-if="record[item.homework_record_id].teacher_comment.comment_images.length">
                        <LImg v-for="(img, i) of record[item.homework_record_id].teacher_comment.comment_images" :key="i" :src="img"/>
                      </div>
                      <div class="photos" v-if="record[item.homework_record_id].teacher_comment.comment_audios.length">
                        <audio v-for="(img, i) of record[item.homework_record_id].teacher_comment.comment_audios" :key="i" :src="img.audio_path" controls/>
                      </div>
                      <div class="photos" v-if="record[item.homework_record_id].teacher_comment.comment_videos.length">
                        <video v-for="(img, i) of record[item.homework_record_id].teacher_comment.comment_videos" :key="i" :src="img" controls/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <commentModal v-if="commentVisible" :item="modalData"/>
    <replyModal v-if="replyVisible" :item="modalData"/>
  </a-modal>
</template>
<style lang="scss" scoped>
.photos {
  margin: 10px 0 !important;
}
.comment .item {
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: none;
  }
  .user {
    margin: 0 10px 0 0;
  }
  .delete {
    display: none;
    color: #f00;
  }
  &:hover .delete {
    display: block;
  }
}
.comment-title {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
</style>

<script>
import commentModal from './commentModal'
import replyModal from './replyModal'
export default {
  name: 'DetailModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    commentModal,
    replyModal,
  },

  data() {
    return {
      ModalTitle: '学员列表',
      visible: false,
      confirmLoading: false,
      homework: {},
      record: {},

      commentVisible: false,
      replyVisible: false
    }
  },

  created() {
    this.visible = true
    this.getDetail()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail() { // 100279 this.item.homework_id
      let res = await this.$store.dispatch('homeworkDetailAction', { data: { assign_id: this.item.homework_id } })
      console.log(res)
      this.homework = res.data
    },
    async getRecord(record_id) {
      let res = await this.$store.dispatch('homeworkRecordAction', { data: { record_id } })
      this.record[record_id] = res.data
      this.$forceUpdate()
    },
    handleOk() {
      this.parent.hideListModal(1)
    },
    handleCancel() {
      this.parent.hideListModal(0)
    },
    deleteComment(record_id, comment_id) {
      let that = this
      this.$confirm({
        title: `您确定要删除评论吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('homeworkDeleteCommentAction', { data: { comment_id } })
            if (res) {
              that.getRecord(record_id)
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    async changeRate(item) {
      let data = {
        record_id: item.homework_record_id,
        comment_rate: item.homework_comment_rate
      }
      let res = await this.$store.dispatch('homeworkRateAction', {data})
      console.log(res)
    },
    showCommentModal(item) {
      this.modalData = {
        record_id: item,
        record: this.record[item]
      }
      this.commentVisible = true
    },
    hideCommentModal(item) {
      if (item) {
        this.getRecord(item.record_id)
      }
      this.commentVisible = false
    },
    showReplyModal(item) {
      this.modalData = {
        record_id: item,
        record: this.record[item]
      }
      this.replyVisible = true
    },
    hideReplyModal(item) {
      if (item) {
        this.getRecord(item.record_id)
      }
      this.replyVisible = false
    },
  }
}
</script>