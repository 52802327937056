<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="发表" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="评价内容" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-textarea v-decorator="rules.commentContent" :rows="4"/>
      </a-form-item>
      <a-form-item label="作业评星" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-rate v-decorator="rules.commentRate" />
      </a-form-item>
      <a-form-item label="点评标签" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-input v-decorator="rules.commentTags" :rows="4"/>
      </a-form-item>
      <a-form-item label="动态图片" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <!-- <a-upload v-decorator="rules.commentImages" name="file" 
          multiple
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeImageUpload"
          listType="picture"
          :defaultFileList="fileList"
          @change="changeFile">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload> -->
        <div style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal('comment_images')">选择图片</a-button>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
              <LImg :src="img" style="width: 100px; height: 100px;"/>
              <div class="close" @click="closeImg2($event, index)">&times;</div>
            </div>
          </div>
        </div> 
      </a-form-item>
      <a-form-item label="动态音频" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.commentAudios" name="file"
          multiple
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeUpload"
          :defaultFileList="fileList2"
          @change="changeFile2">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="动态视频" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.commentVideos" name="file"
          multiple
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeUpload"
          :defaultFileList="fileList3"
          @change="changeFile3">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import ImageModal from '@/views/modal/images'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '发表评论',

      form: this.$form.createForm(this),
      rules: {
        commentContent: [ 'comment_content',
          {rules: [{ required: true, message: '请输入评价内容!' }]}
        ],
        commentRate: [ 'comment_rate', {rules: []} ],
        commentTags: [ 'comment_tags', {rules: []} ],
        commentImages: [ 'comment_images', {rules: []} ],
        commentAudios: [ 'comment_audios', {rules: []} ],
        commentVideos: [ 'comment_videos', {rules: []} ],
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: [],
      fileList2: [],
      fileList3: [],

      imageRoot: url.imageRoot,
      imageUrl: '',
      imageUrls: [],
      imagesVisible: false,
      imageKey: '',
    }
  },

  async created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'comment_images') {
          this.imageUrls.push(image.path)
        } else {
          this.imageUrl = image.path
        }
      }
      this.imagesVisible = false
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          // if (this.fileList.length) {
          //   let arr = []
          //   for (let file of this.fileList) {
          //     if (file.response.key) {
          //       arr.push(file.response.key)
          //     }
          //   }
          //   params.comment_images = arr
          // }
          params.comment_images = this.imageUrls
          if (this.fileList.length) {
            let arr = []
            for (let file of this.fileList2) {
              if (file.response.key) {
                arr.push(file.response.key)
              }
            }
            params.comment_audios = arr
          }
          if (this.fileList.length) {
            let arr = []
            for (let file of this.fileList3) {
              if (file.response.key) {
                arr.push(file.response.key)
              }
            }
            params.comment_videos = arr
          }
          params.record_id = this.item.record_id
          params.comment_entity = this.item.record.comment_entity
          let res = await this.$store.dispatch('homeworkAddReplyAction', { data: params })
          if(res) {
            this.parent.hideReplyModal(this.item)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },  
    handleCancel() {
      this.parent.hideReplyModal()
    },

    async beforeImageUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('imageTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    changeFile({ file, fileList }) {
      this.fileList = fileList
      console.log(fileList)
    },
    changeFile2({ file, fileList }) {
      this.fileList2 = fileList
      console.log(fileList)
    },
    changeFile3({ file, fileList }) {
      this.fileList3 = fileList
      console.log(fileList)
    },
  }
}
</script>
