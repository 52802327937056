<template>
  <div class="zcxy">
    <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>教务中心</a-breadcrumb-item>
        <a-breadcrumb-item>作业点评</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canSave">
              <a-button type="primary" icon="plus" @click="showEditModal()">新增作业</a-button>
            </a-form-item>
          </a-form>
        </div>

        <div class="search">
          <a-form layout='inline' @submit="searchList">

             <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='searchData.is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>

             <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>作业标题</span>
                </template>
                <a-input allowClear v-model.trim="searchData.homework_title" placeholder="作业标题" style="width: 160px"/>
                </a-tooltip>
            </a-form-item>

            <!-- <a-form-item v-if="!id">
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>班级名称</span>
                </template>
                <a-select allowClear showSearch :filterOption="filterOption" placeholder="请选择班级" v-model="searchParams.class_id" style="width: 200px">
                  <a-select-option v-for="(item, index) of category" :key="index" :value="item.class_id">{{ item.filter_name }}</a-select-option>
                </a-select> 
              </a-tooltip>
            </a-form-item> -->

            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>

      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="homework_id"
          :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>


          <template slot="homework_title" slot-scope="text, record">
            <a slot="action" href="javascript:;" @click="showDetailModal(record)">{{text}}</a>
          </template>

          <template slot="homework_employee" slot-scope="text, record">
            <span>{{record.homework_employee.employee_name}}</span>
          </template>
          
          <template slot="action" slot-scope="text,record">
            
            <a slot="action" href="javascript:;" @click="showDetailModal(record)">详情</a>
            <a-divider type="vertical" />
            <a slot="action" href="javascript:;" @click="showListModal(record)">学员列表</a>
            <a-divider type="vertical" />
            <a slot="action" href="javascript:;" @click="showStatistics(record)">统计</a>
            <a-divider v-if="canDelete" type="vertical" />
            <a v-if="canDelete" href="javascript:;" @click="deleteHomework(record)">删除</a>
          </template>
        </a-table>
      </div>
        <div class="page">
          <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
          >
              <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
              </template>
            </a-pagination>
        </div>
    </div>
    <editModal v-if="visible" :item="modalData"/>
    <detailModal v-if="detailVisible" :item="modalData"/>
    <listModal v-if="listVisible" :item="modalData"/>
    <statistics v-if="isStatistics" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '作业标题', width: '220px', dataIndex: 'homework_title', key: '1', fixed: 'left' , scopedSlots: { customRender: 'homework_title' } },
  { title: '班级名称', dataIndex: 'classes_id', key: '2'},
  { title: '已完成', dataIndex: 'finish_count', key: '3',align:"center" },
  { title: '未上交', dataIndex: 'homework_no_commit', key: '6' ,align:"center" },
  { title: '未点评', dataIndex: 'homework_no_comment', key: '7' ,align:"center" },
  { title: '未查看', dataIndex: 'homework_no_read', key: '8' ,align:"center" },
  { title: '发布时间', dataIndex: 'homework_date', key: '9' },
  { title: '发布账户', dataIndex: 'homework_employee', key: 'homework_employee', scopedSlots: { customRender: 'homework_employee' } },
  { title: '操作', key: 'operation', width: 190, scopedSlots: { customRender: 'action' },align:'right'}
]

import editModal from './editModal'
import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
import detailModal from './detailModal'
import listModal from './listModal'
import statistics from './statistics/index'

export default {
  name: 'bjgl',
  mixins: [ tableMixins , authority ],
  provide() {
    return {
      parent: this
    }
  },
  props: {
    id: Number
  },
  components: {
    editModal,
    detailModal,
    listModal,
    statistics,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchData: {is_sub:0},
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      category: [],
      modalData: {},
      detailVisible: false,
      listVisible: false,
      commentVisible: false,
      isStatistics: false,
      isTestCanvas: true,
      authType:['market','xuetang'],
    }
  },
  beforeCreate () {
      if(!this.$ls.get('is_sub')){
          this.$ls.set('is_sub', 0)
      }
  },
  created() {
    this.getList()
    this.getCate()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getCate() {
      let res = await this.$store.dispatch('searchClassesAction', {})
      this.category = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      if (this.id) {
        this.searchParams.class_id = this.id
      }

      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.search.is_sub = this.$ls.get('is_sub')
      this.searchData.is_sub = this.$ls.get('is_sub')
      let res = await this.$store.dispatch('scheduleHomeworkAction', this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.getList()
    },
    showEditModal(item) {
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    
    deleteHomework(item) {
      let that = this
      this.$confirm({
        title: `确定要删除该作业?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('homeworkDeleteAction', { assign_id: item.homework_id })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleIsSub(e){
        this.$ls.set('is_sub', e)
        this.searchData.is_sub = e
        this.$forceUpdate()
    },
    showDetailModal(item) {
      this.modalData = item
      this.detailVisible = true
    },
    hideDetailModal(type) {
      if (type === 1) {
        
      }
      this.detailVisible = false
    },
    showListModal(item) {
      this.modalData = item
      this.listVisible = true
    },
    hideListModal(type) {
      if (type === 1) {
        
      }
      this.listVisible = false
    },
    showStatistics(item){
      this.modalData = item
      this.isStatistics = true
    },
    hideStatistics(type){
      this.isStatistics = false
    }
  }
}
</script>
